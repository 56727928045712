import React, {
  forwardRef, ForwardRefExoticComponent, memo, useMemo,
} from 'react';
import { IconProps } from './types';

import {
  Close,
  Plus,
  Settings,
  Bag,
  Circle,
  Copy,
  Menu,
  App,
  ArrowLeft,
  ArrowLeftSmall,
  ArrowRight,
  Check,
  CheckSmall,
  PlusSmall,
  Search,
  Info,
  InfoW,
  Clip,
  Trash,
  Dnd,
  Refresh,
  Cross,
  Triangle,
  Triangle2,
  CheckRounded,
  CloseRounded,
  CloseSmall,
  InfoLight,
  TrashBig,
  CopyBig,
  Disabled,
  Soon,
  LinkArrow,
  Minus,
  Multiple,
  App4,
  Download,
  Eye,
  CrossedEye,
  TriangleInfo,
  CrossInCircle,
  CautionBig,
  Question,
  ExclamationGrey,
  ExclamationOrange,
  ExclamationMedium,
  ExclamationOrangeMedium,
  DiscordSmall,
  Search2,
  CrossMini,
  Lightning,
  Verification,
  Folder,
  Edit,
  Unlimited,
} from './IconsList';

import { iconParams, getStyleDimensions } from './helpers';

const icons = {
  close: <Close />,
  plus: <Plus />,
  settings: <Settings />,
  bag: <Bag />,
  menu: <Menu />,
  app: <App />,
  copy: <Copy />,
  circle: <Circle />,
  arrow_left: <ArrowLeft />,
  arrow_left_small: <ArrowLeftSmall />,
  arrow_right: <ArrowRight />,
  check: <Check />,
  check_small: <CheckSmall />,
  plus_small: <PlusSmall />,
  search: <Search />,
  info: <Info />,
  info_w: <InfoW />,
  clip: <Clip />,
  trash: <Trash />,
  triangle: <Triangle />,
  triangle2: <Triangle2 />,
  dnd: <Dnd />,
  refresh: <Refresh />,
  cross: <Cross />,
  check_rounded: <CheckRounded />,
  close_rounded: <CloseRounded />,
  close_small: <CloseSmall />,
  info_light: <InfoLight />,
  trash_big: <TrashBig />,
  copy_big: <CopyBig />,
  disabled: <Disabled />,
  soon: <Soon />,
  link_arrow: <LinkArrow />,
  minus: <Minus />,
  multiple: <Multiple />,
  'app-4': <App4 />,
  download: <Download />,
  eye: <Eye />,
  crossed_eye: <CrossedEye />,
  triangle_info: <TriangleInfo />,
  cross_in_circle: <CrossInCircle />,
  caution_big: <CautionBig />,
  question: <Question />,
  exclamation_grey: <ExclamationGrey />,
  exclamation_orange: <ExclamationOrange />,
  exclamation_medium: <ExclamationMedium />,
  exclamation_orange_medium: <ExclamationOrangeMedium />,
  discord_small: <DiscordSmall />,
  search2: <Search2 />,
  cross_mini: <CrossMini />,
  lightning: <Lightning />,
  verification: <Verification />,
  folder: <Folder />,
  edit: <Edit />,
  unlimited: <Unlimited />,
};

export const Icon: ForwardRefExoticComponent<IconProps> = memo(forwardRef(({
  name,
  height,
  width,
  className,
  onClick,
  onMouseEnter,
  onMouseLeave,
  fill = 'currentColor',
}, ref?: React.Ref<SVGSVGElement>) => {
  const style = useMemo(() => {
    return (height || width)
      ? { ...(width ? { width: `${width}px`, minWidth: `${width}px` } : {}), height: `${height || width}px` }
      : iconParams[name] ? getStyleDimensions(iconParams[name]) : undefined;
  }, [height, width, name]);

  return (
    <svg
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
      ref={ref}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...iconParams[name]}
    >
      {icons[name]}
    </svg>
  );
}));
